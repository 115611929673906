<template>
    <div class="rt-wraper">
      <div class="row">
        <div class="col-6">
          <h1>Introduction</h1>
          <div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/5b7eba72a4e54d6193cd09804624a130" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
        </div>
        <div class="col-6">
          <h1>Risk Questions - Part 1</h1>
          <div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/6a317556ccf84f10b0dc52ae5515538a" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
        </div>
        <div class="col-6">
          <h1>Risk Questions - Part 2</h1>
          <div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/c3b0e13c2590454aa8cd942302e8ff09" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
        </div>
        <div class="col-6">
          <h1>Risk Questions - Part 3</h1>
          <div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/64abcea7f42c4e17823aa05b4634cfad" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
        </div>
        <div class="col-6">
          <h1>FAQs</h1>
          <div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/8cc81669b71c4bfeb98dfaea59fa590f" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
        </div>
        <div class="col-6">
          <h1>Users</h1>
          <div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/248d9bf19d814c77a3b7511d13987016" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
        </div>
      </div>

    </div>
</template>
<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {BLink} from 'bootstrap-vue'
export default {
    components: {
        BLink,
        VuexyLogo
    }
}
</script>
<style lang="scss">
    .rt-wraper{padding:30px 40px;display:flex;flex-direction:column;}
    .rt-wraper .logo{align-self:flex-start;margin-bottom:20px;}
    .rt-wraper .logo svg{height:43px;width:160px;}
    .rt-wraper h1{margin:0 0 20px;color:#222;font-size:24px;font-weight:600;}
    .rt-wraper ol{color:#222;}
    .rt-wraper ol li{color:#222;margin-bottom:15px;font-size:18px;line-height:26px;color:#222;font-weight:600;}
    .rt-wraper ol li ol{color:#222;}
    .rt-wraper ol li ol li{color:#222;margin-bottom:10px;font-size:16px;line-height:26px;font-weight:400;}
    .rt-wraper .btn-download{align-self:flex-start;}
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>